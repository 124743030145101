@import url('https://fonts.googleapis.com/css2?family=Encode+Sans:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import url(./landingIntro.css);
@import url(./rating.css);
@import url(./sectorcovered.css);
@import url(./featuresExplain.css);
@import url(./features.css);
@import url(./results.css);
@import url(./advanceFeaturesExplain.css);
@import url(./featuresLeverage.css);
@import url(./anyDevicesAccess.css);
@import url(./trustBanner.css);
@import url(./workdemo.css);
@import url(./appbanner.css);
@import url(./requestdemo.css);
@import url(./contactus.css);
@import url(./solutionfor.css);
@import url(./benefits.css);
@import url(./customer_swipper.css);
@import url(./footer.css);
@import url(./terms_conditions.css);



.project-landingpage-main{
    width: 100%;
    height: max-content;
    position: relative;
    overflow: hidden;
    font-family: 'Inter', sans-serif;
}

.project-landingpage-main .landingpage-content-main{
    width: 100%;
    height: 100%;
    position: relative;
    overflow-y: auto;
    overflow-x: hidden;

    display: flex;
    flex-direction: column;
    
}

.project-landing-header-main{
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10;
    width: 100%;
    height: 70px;
    background: var(--bg-light-color);
    display: flex;
    align-items: center;
    justify-content: center;
}
.project-landing-header-main .header-content-main{
    width: 90%;
    max-width: var(--max-width);
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.project-landing-header-main .header-logo{
    width: 170px;
    height: 50px;
}
.project-landing-header-main .header-logo img{
    width: inherit;
    height: inherit;
    object-fit: contain;
}
.project-landing-header-main .header-right-section{
    width: max-content;
    height: 100%;

    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 20px;
}
.project-landing-header-main .right-section-menus{
    width: max-content;
    height: 100%;

    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 50px;
}
.project-landing-header-main .right-section-menus .header-menu-item{

    width: max-content;
    height: 100%;

    display: flex;
    align-items: center;
    position: relative;
    
}
.project-landing-header-main .right-section-menus .header-menu-item .menu{
    display: flex;
    align-items: center;
    gap: 5px;
    font-size: 17px;
    font-weight: 500;
    cursor: pointer;
}
.project-landing-header-main .right-section-menus .header-menu-item .menu .icon{
    margin-top: 2px;
}

.project-landing-header-main .right-section-menus .header-menu-item:hover .menu{
    color: var(--primary-color);   
}
.project-landing-header-main .right-section-menus .header-menu-item:hover .menu .icon svg path{
    stroke: var(--primary-color);   
    stroke-width: 3px;
}
.project-landing-header-main .right-section-menus .header-menu-item .active-bar{
    
    position: absolute;
    top: 75%;
    left: 0;
    z-index: 1000;

    display: none;
    width: 25px;
    height: 3px;
    background: var(--primary-color);

}
.project-landing-header-main .right-section-menus .header-menu-item:hover .active-bar{
    
   display: flex !important;

}

.project-landing-header-main .header-menu-item .dropdown{

    position: absolute;

    top: 100%;
    left: 0;
    z-index: 10000;
    width: 600px;
    height: max-content;
    max-height: 500px;
    padding: 15px;
    display: none;
    flex-wrap: wrap;
    gap: 20px;
    background: var(--bg-light-color);
    border: 1px solid var(--border-light-color);
    border-radius: 6px;
    box-shadow: 0 5px 10px 0 rgba(138, 155, 165, .15);
    transition: all 200ms linear;
}
.project-landing-header-main .header-menu-item:hover .dropdown{

display: flex !important;
}
.project-landing-header-main .dropdown .dropdown-menu-item{
    width: 48%;
    height: max-content;
    
    display: flex;
    align-items: center;
    gap: 10px;
}
.project-landing-header-main .dropdown .dropdown-menu-item .icon{
    width: 24px;
    height: 24px;
}
.project-landing-header-main .dropdown .dropdown-menu-item .icon svg{
    width: inherit;
    height: inherit;
}
.project-landing-header-main .dropdown .dropdown-menu-item .detials{
  width: calc(100% - 50px);
  height: max-content;

  display: flex;
  flex-direction: column;
  gap: 5px;
}
.project-landing-header-main .dropdown .dropdown-menu-item .detials .label{
    font-size: 15px;
    font-weight: 500;
    color: var(--dark-font-color);
    font-family: 'Inter', sans-serif;
    white-space: nowrap;
}
.project-landing-header-main .dropdown .dropdown-menu-item .detials .description{
    font-size: 12px;
    color: var(--gray-font-color);
    font-weight: 400;
    display: block;
    white-space: normal;
    line-height: 16px;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}
.project-landing-header-main .dropdown .dropdown-menu-item:hover .label{
    color: var(--primary-color);
}


.project-landing-header-main .header-action-buttons{
    width: max-content;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 10px;
}
.project-landing-header-main .header-action-buttons .header-action-btn{
    width: max-content;
    padding: .5rem 1.5rem;
    line-height: 1.5rem;
    /* height: 50px; */
    /* background: var(--primary-color); */
    color: var(--primary-color);
    border: 2px solid var(--primary-color);
    font-size: 15px;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    cursor: pointer;
    border-radius: .25rem;
    transition: all .25s ease;
}
.project-landing-header-main .header-action-buttons .header-action-btn:hover{
    background: var(--primary-color);
    color: var(--light-font-color);

}
.project-landing-header-main .header-action-buttons .header-action-btn .label{
    white-space: nowrap;
}

.project-landing-header-main .header-right-mobile-menu{
    display: none;
    margin-top: 5px;
    width: 32px;
    height: 32px;
    cursor: pointer;
}
.project-landing-header-main .header-right-mobile-menu .icon{
    width: inherit;
    height: inherit;
}
.project-landing-header-main .header-right-mobile-menu svg{
    width: inherit;
    height: inherit;
}

@media only screen and (max-width: 1050px) {

    .project-landing-header-main .header-right-mobile-menu{
        display: flex !important;
    }
    .project-landing-header-main .header-right-section{
        display: none;

        position: absolute;
        top: 70px;
        left: 0%;
        width: 100vw;
        height: 400px;
        background: var(--bg-color);
    }
    .project-landing-header-main .header-right-section-active{
        display: flex !important;
    } 
    .project-landing-header-main .header-right-section .right-section-menus{
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        gap: 15px;
        padding: 0 2rem;
    }
    .project-landing-header-main .right-section-menus .header-menu-item{
        height: max-content;
        min-height: 50px;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
    }

    .project-landing-header-main .header-menu-item .dropdown{
        width: max-content;
        flex-direction: column;
    }
    .project-landing-header-main .right-section-menus .header-menu-item:hover .active-bar{
        display: none !important;
    }
    .project-landing-header-main .dropdown .dropdown-menu-item{
        width: 100%;
    }
}

@media only screen and (max-width: 500px) {

    .project-landing-header-main .header-action-buttons .header-action-btn{
        display: none;
    }
}