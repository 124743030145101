/* @import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap'); */
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');


@import url(./landingpage/landingpage.css);
@import url(./pricing.css);
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body {
    /* font-family: 'Inter', sans-serif; */
    font-family: 'Inter', sans-serif;
}
:root {

    /* Widths */
    --sidebar-width: 300px;
    --header-height: 50px;
    --max-width: 1290px;
    /* Colors */
    --primary-button-color: #000;
    --primary-color: #FF7622;
    --primary-input-shadow: #ffa46c4b;
    --primary-dark-color: #d15000;
    --primary-trans-color: rgba(255, 233, 219, 0.8);
    --primary-toggle-trans-color: #ffd5ba;
    --blocking-color: rgba(6, 4, 4, 0.4);
    --border-color: rgba(0, 0, 0, 0.1);
    --border-dark-color: #D6D8E7;
    --border-warning-color: rgb(229, 76, 64);
    --border-light-color: #E3E4EE;
    --button-border-color: #B8BBD4;
    --background-gray: #D0D3E3;
    --hover-color: rgba(0, 0, 0, 0.04);
    --popup-blocking-color: rgba(0, 0, 0, 0.6);
    --light-font-color: #fff;
    --dark-font-color: #303030;
    --mid-black-font-color: #31284C;
    --black-font-color: #000;
    --gray-font-color: #9193a3;
    --gray2-font-color: rgba(19,22,23,.7);
    --gray-dark-font-color: #2329681a;
    --bg-color: #F6F6F6;
    --bg-mid-light-color: #fafafa;
    --bg-warning-color: #fcedec;
    --bg-dark-color: #F4F4F7;
    --bg-light-color: #fff;
    --bg-light-gray-color: #ccc;
    --bg-warning-color: #FDEEEC;

    --bg-sidebar-global: #111926 ;
    --bg-sidebar-global-active: #3d4452 ;
    --bg-sidebar-project: #F9F8F9;

    --bg-circle-chart: #eee;

    --bg-worksheet-group: #F2F6FE;
    --bg-worksheet-subgroup: #F2F2F6;
    --bg-worksheet-filters: #F8F9FA;

    --active-color: #2166de;
    --active2-color: #3D90ED;
    --danger-color: #f44336;
    --warning-color: #F29D41;
    --success-color: #07C07E;

    --transparent-active-color: rgb(40, 85, 255, 0.1);
    --transparent-danger-color: rgb(244, 67, 54, 0.1);
    --transparent-warning-color: rgb(242, 157, 65, 0.1);
    --transparent-success-color: rgba(7, 192, 126, 0.1);
}

.project-container-main {
    width: 100%;
    height: 100vh;
    position: relative;
    overflow-x: hidden;
}
.project-main-container {
    width: 100%;
    height: 100vh;
    position: relative;
    overflow-x: hidden;
}

.project-section-container {
    width: 100%;
    height: 100%;
    display: flex;
    position: relative;
}
.project-section-container-with-plan_expiry_banner {
    height: calc(100% - 25px);

}

.project-content-main {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    background: var(--bg-light-color);
}
.project-content-container{
    width: 100%;
    height: calc(100% - var(--header-height));
    display: flex;
    overflow: hidden;
    background: var(--bg-light-color); 
}

.tabel-action-main{
    position: relative;
    display: flex;
    align-items: center;
    gap: 5px;
    justify-content: flex-end;
}
.tabel-action-main .action-icon{
    cursor: pointer;
    font-size: 15px;
    font-weight: 550;
 }
.tabel-action-main .action-dropdown{
    position: absolute;
    top: 100%;
    right: 0;
    z-index: 100;

    background: var(--bg-light-color);
    border: 1px solid var(--border-light-color);
    border-radius: 10px;

    display: none;
    flex-direction: column;

    width: 150px;
    height: max-content;
 }
 .tabel-action-main .action-dropdown-active{
    display: flex !important;
 }
 .tabel-action-main .action-dropdown .action-dropdown-item{
    width: 100%;
    height: 40px;
    padding-left: 1rem;
    display: flex;
    align-items: center;
    gap: 5px;
    cursor: pointer;
    transition: all .25s;
    border-bottom:1px solid var(--border-light-color);
 }
 .tabel-action-main .action-dropdown .action-dropdown-item:last-child{
    border: none;
 }
 .tabel-action-main .action-dropdown .action-dropdown-item:hover{
    background: var(--hover-color);
 }
 .tabel-action-main .action-dropdown .action-dropdown-item .icon svg path{
    fill: var(--mid-black-font-color)
 }
.tabel-name-main{
    width: 100%;
    height: 20px;
    display: flex;
    align-items: center;
    gap: 10px;
    cursor: pointer;
    white-space: nowrap;
    user-select: none;
}
.tabel-status-main{
    width: 100%;
    display: flex;
    align-items: center;
    gap: 5px;
    cursor: pointer;
    white-space: nowrap;
    user-select: none;
}
.tabel-status-main .tabel-status-span{
    width: 10px;
    height: 10px;
    border-radius: 3px;
}
.tabel-status-main .tabel-status{
    text-transform: capitalize;
}


.tabel-status-main .status-span-0,
.tabel-status-main .status-span-incomplete,
.tabel-status-main .status-span-pending{
    /* border: none; */
    border: 1px solid var(--border-light-color);
    background: var(--bg-light-gray-color);
}
.tabel-status-main .status-span-2,
.tabel-status-main .status-span-completed,
.tabel-status-main .status-span-approve{
    border: none;
    background: var(--success-color);
}
.tabel-status-main .status-span-1 ,
.tabel-status-main .status-span-hold {
    border: none;
    background: var(--warning-color);
}
.tabel-status-main .status-span-stopped,
.tabel-status-main .status-span-reject{
    border: none;
    background: var( --danger-color);
}


.status-background-0,
.status-background-incomplete,
.status-background-pending{
    cursor: default;
    white-space: nowrap;
    user-select: none;
    width: max-content;
    padding: .2rem 1rem;
    font-size: 14px;
    border-radius: 1000px;
    border: none;
    background: var(--bg-light-gray-color);
    color: var(--mid-black-font-color);
}
.status-background-2,
.status-background-completed,
.status-background-approve{
    cursor: default;
    white-space: nowrap;
    user-select: none;
    width: max-content;
    padding: .2rem 1rem;
    font-size: 14px;
    border-radius: 1000px;
    border: none;
    color: var(--success-color);
    background: var(--transparent-success-color);
}
.status-background-1 ,
.status-background-hold {
    cursor: default;
    white-space: nowrap;
    user-select: none;
    width: max-content;
    padding: .2rem 1rem;
    font-size: 14px;
    border-radius: 1000px;
    border: none;
    color: var(--warning-color);
    background: var(--transparent-warning-color);
}
.status-background-3,
.status-background-stopped,
.status-background-reject{
    cursor: default;
    white-space: nowrap;
    user-select: none;
    width: max-content;
    padding: .2rem 1rem;
    font-size: 14px;
    border-radius: 1000px;
    border: none;
    color: var(--danger-color);
    background: var(--transparent-danger-color);
}


 .task-tabel-status{
    text-transform: capitalize;
    font-size: 13px;
    width: 150px;
    padding: .5rem;
    height: 25px;

    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    border: 1px solid var(--border-color);
    background: var(--bg-light-color);
    color: #000;
}

 .task-tabel-status-0,
 .task-tabel-status-2{
    background: hsla(0,0%,59.2%,.08);
    border-color: #979797;
}
 .task-tabel-status-1{
    background: rgba(61,144,237,.2);
    border-color: #3d90ed;
}
 .task-tabel-status-3,
 .task-tabel-status-6{
    background: rgba(235,184,64,.08);
    border-color: #ebb840;
}
 .task-tabel-status-4{
    background: rgba(104,208,131,.08);
    border-color: #68d083;
}
 .task-tabel-status-5,
 .task-tabel-status-7{
    background: rgba(234,58,60,.08);
    border-color: #ea3a3c;
}

.tabel-name, .tabel-id{
    width: 100%;
    height: max-content;
    display: flex;
    align-items: center;
    gap: 10px;
    color: var(--primary-color);
    text-decoration: underline;
    cursor: pointer;
    white-space: nowrap;
    
 }
 .tabel-row-arrow{
    cursor: pointer;
    transition: all .25s;
 }
 .tabel-row-arrow-open{
    transform: rotate(90deg);
 }
.tabel-header {
    background: var(--bg-worksheet-filters) !important;
 }
 .tabel-header tr{
    border-radius:  10px 10px 0 0 !important;
    border: 1px solid #EEEEEE !important;
  }
 .tabel-header tr th{
    padding: 8px 10px;
    font-weight: 500;
    background: #F8F9FA !important;
    border-top: 1px solid #EEEEEE !important;
    border-bottom: 1px solid #EEEEEE !important;
    color: #9193A3 !important;
    font-weight: 500 !important;
 }
 
 .tabel-header tr th:first-child{
    border-radius: 10px 0 0 0 !important;
    border-left: 1px solid #EEEEEE !important;
 }
 .tabel-header tr th:last-child{
     border-radius: 0 10px 0 0 !important;
     border-right: 1px solid #EEEEEE !important;
 }
 .tabel-body tr td{
    padding: 8px 10px;
    white-space: nowrap;
    border-bottom: 1px solid #EEEEEE !important;
 }
 .tabel-body tr td:first-child{
    border-left: 1px solid #EEEEEE !important;
 }
 .tabel-body tr td:last-child{
     border-right: 1px solid #EEEEEE !important;
 }
.tabel-header-items{
    font-size: 14px !important;
    font-weight: 500 !important;
    color: var(--mid-black-font-color) !important;
    background-color: none !important;
    background: inherit !important;
    white-space: nowrap;
 }
 .table-default-toggle-item{
    width: 30px !important;
 }
.table-default-toggle-item .checkboxinput-main{
    width: max-content !important;
 }

 .project-project_estimate-main .tabel-name{
    text-decoration: none !important;
    color: var(--mid-black-font-color) !important;
}
.project-client_invoice-main .tabel-name{

    color: var(--primary-color) !important;
    text-decoration: underline !important;
}








a {
    text-decoration: none;
    color: #000;
}

*::-webkit-scrollbar-track {
    background-color: #F5F5F5;
    border: 1px solid #b8bbd4;

}

*::-webkit-scrollbar {
    width: 8px;
    height: 8px;
}

*::-webkit-scrollbar-thumb {
    background-color: #b8bbd4;
}


.animateXLeft {
    opacity: 0;
    transform: translateX(-100%);
}

.animateXRight {
    opacity: 0;
    transform: translateX(100%);
}

.animateXLeftactive {
    animation: sliderXLeft 1s cubic-bezier(0.15, 0, 0.333, 1) forwards;
    animation-delay: .6s;
}

.animateXRightactive {
    animation: sliderXRight 0.25s cubic-bezier(0.15, 0, 0.333, 1) forwards;
    animation-delay: 0s;
}

.animateY {
    opacity: 0;
    transform: translateY(50%) scale(.5);
}

.animateYactive {
    animation: sliderY .2s cubic-bezier(0.15, 0, 0.333, 1) forwards;
    animation-delay: .2s;
}


.animateYInvert {

    opacity: 0;
    transform: translateY(-100%);
}


.animateYInvertactive {
    animation: sliderYInvert 1s cubic-bezier(0.15, 0, 0.333, 1) forwards;
    animation-delay: .2s;
}


.attachments-section-main{
    width: 100%;
    height: max-content;

    display: flex;
    gap: 15px;
}
.attachments-section-main .attachment-input{
   width: 150px;
   height: 150px;


   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: center;
   position: relative;
   background: var(--bg-light-color);
   border: 1px dashed var(--button-border-color);
   border-radius: 10px;
   user-select: none;
   cursor: pointer;
}
.attachments-section-main .attachment-input input{
    display: none;
}
.attachments-section-main .attachment-input .attachment-add-icon{
    width: 30px;
    height: 30px;
    
}
.attachments-section-main .attachment-input .attachment-add-icon svg{
    width: inherit;
    height: inherit;

}
.attachments-section-main .attachment-input .attachment-add-icon svg path{
    fill: var(--gray-font-color);
}
.attachments-section-main .attachment-input .attachment-label{
    font-size: 15px;
    font-weight: 500;
    color: var(--primary-color);
}
.attachments-section-main .attachment-input .attachment-limit{
    font-size: 13px;
    color: var(--gray-font-color);
}
.attachments-section-main .attachments-section-files{
    width: 100%;
    height: max-content;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 15px;
}
.attachments-section-main .attachments-section-files .attachment-file{
    width: 150px;
    height: 150px;

    display: flex;
    flex-direction: column;
    border-radius: 10px;
    border: 1px solid var(--button-border-color);
    position: relative;
}
.attachments-section-main .attachments-section-files .attachment-file .attachment-delete-btn{
    position: absolute;
    top:-10px;
    right:-10px;

    z-index: 10;
    background: var(--bg-light-gray-color);

    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 1000px;
    cursor: pointer;
}
.attachments-section-main .attachments-section-files .attachment-file .attachment-delete-btn svg{
    width: 16px;
    height: 16px;
}
.attachments-section-main .attachments-section-files .attachment-file .attachment-delete-btn svg path{
    fill:  var(--black-font-color);
}
.attachments-section-main .attachments-section-files .attachment-file .attachment-file-preview{
    width: 100%;
    height: calc(100% - 30px);
    cursor: pointer;
}
.attachments-section-main .attachments-section-files .attachment-file .attachment-file-preview img{
    width: 100%;
    height: 100%;
    object-fit: contain;
}
.attachments-section-main .attachments-section-files .attachment-file .attachment-file-footer{
    width: 100%;
    height: 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 .5rem;
    border-top: 1px solid var(--button-border-color);
}
.attachments-section-main .attachments-section-files .attachment-file .attachment-file-footer .file-name{
    width: 85%;
    font-size: 12px;
    color: var(--black-font-color);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

}
.attachments-section-main .attachments-section-files .attachment-file .attachment-file-footer .file-download{
    width: 18px;
    height: 18px;
    cursor: pointer;
}
.attachments-section-main .attachments-section-files .attachment-file .attachment-file-footer .file-download svg{
    width: inherit;
    height: inherit;
}
.attachments-section-main .attachments-section-files .attachment-file .attachment-file-footer .file-download svg path{
    fill: var(--black-font-color);
}




.comments-section-main{
    width: 100%;
    height: max-content;

    display: flex;
}
.comments-section-main .comments-section-items{
    width: 100%;
    height: max-content;
    display: flex;
    flex-direction: column;
}
.comments-section-main .comments-section-items .comments-input-main{
    width: 100%;
    height: max-content;
    display: flex;
    justify-content: space-between;
    padding: 1rem 0;
}
.comments-section-main .comments-section-items .comments-input-main .comments-input-usericon{
    width: 40px;
    height: 40px;   
    border-radius: 1000px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 15px;
    color: var(--light-font-color);
    background: var(--danger-color);
}
.comments-section-main .comments-section-items .comments-input-main .comments-input{
    width: calc(100% - 50px);
    height: max-content;
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 10px;
    border: 1px solid var(--border-light-color);
    padding: 10px;
}
.comments-section-main .comments-section-items .comments-input-main .comments-input-active{
    border-color: var(--primary-color);
}

.comments-section-main .comments-section-items .comments-input-main .comments-input .comments-input-textarea{
    width: 100%;
    height: max-content;
}
.comments-section-main .comments-section-items .comments-input-main .comments-input .comments-input-textarea textarea{
    width: 100%;
    height: max-content;
    min-height: 15px;
    outline: none;
    border: none;
    resize:none;
    font-size: 14px;
}
.comments-section-main .comments-section-items .comments-input-main .comments-input-active .comments-input-textarea textarea{
    min-height: 80px;
}
.comments-section-main .comments-section-items .comments-input-main .comments-input .comments-input-controls{
    width: 100%;
    height: max-content;
    display: flex;
    align-items: center;
    gap: 10px;
}
.comments-section-main .comments-section-items .comments-input-main .comments-input .comments-input-controls .comments-input-control-sendmsg,
.comments-section-main .comments-section-items .comments-input-main .comments-input .comments-input-controls .comments-input-control-adduser{
    display: flex;
    align-items: center;
    gap: 10px;
    cursor: default;
}
.comments-section-main .comments-section-items .comments-input-main .comments-input .comments-input-controls .comments-input-control-sendmsg .icon,
.comments-section-main .comments-section-items .comments-input-main .comments-input .comments-input-controls .comments-input-control-adduser .icon{
    width: 28px;
    height: 28px;
    border-radius: 1000px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--bg-light-gray-color);
}
.comments-section-main .comments-section-items .comments-input-main .comments-input .comments-input-controls .comments-input-control-sendmsg .icon svg,
.comments-section-main .comments-section-items .comments-input-main .comments-input .comments-input-controls .comments-input-control-adduser .icon svg{
    width: 1em;
    height: 1em;
    fill: var(--light-font-color);
    
}

.comments-section-main .comments-section-items .comments-input-main .comments-input .comments-input-controls .comments-input-control-adduser .icon{
    background: var(--active-color);
    cursor: pointer;
}

.comments-section-main .comments-section-items .comments-input-main .comments-input-active .comments-input-controls-active .comments-input-control-sendmsg .icon{
    background: var(--primary-color);
    cursor: pointer;
}

.comments-section-main .comments-section-items .comments-item-main{
    width: 100%;
    height: max-content;
    display: flex;
    justify-content: space-between;
    padding: 1rem 0;
    border-bottom: 1px solid var(--border-light-color);
}
.comments-section-main .comments-section-items .comments-item-main .comments-item-usericon{
    width: 40px;
    height: 40px;   
    border-radius: 1000px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 15px;
    color: var(--light-font-color);
    background: var(--bg-light-gray-color);
}
.comments-section-main .comments-section-items .comments-item-main .comments-item-detials{
    width: calc(100% - 50px);
    height: max-content;

    display: flex;
    flex-direction: column;
    gap: 10px;
}
.comments-section-main .comments-section-items .comments-item-main .comments-item-detials .detials-head{
    width: 100%;
    height: max-content;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.comments-section-main .comments-section-items .comments-item-main .comments-item-detials .detials-head .left{
    display: flex;
    flex-direction: column;
}
.comments-section-main .comments-section-items .comments-item-main .comments-item-detials .detials-head .right{
    display: flex;
    align-items: center;
    justify-content: flex-end;
}
.comments-section-main .comments-section-items .comments-item-main .comments-item-detials .detials-head .right .delete-icon{
    height: 28px;
    cursor: pointer;
}
.comments-section-main .comments-section-items .comments-item-main .comments-item-detials .detials-head .right .delete-icon svg{
    height: inherit;
}
.comments-section-main .comments-section-items .comments-item-main .comments-item-detials .detials-head .right .delete-icon svg path{
    fill: var(--danger-color);
}
.comments-section-main .comments-section-items .comments-item-main .comments-item-detials .detials-head .left .name{
    font-size: 15px;
    font-weight: 550;
    color: var(--black-font-color);
}
.comments-section-main .comments-section-items .comments-item-main .comments-item-detials .detials-head .left .time{
    font-size: 14px;
    font-weight: 400;
    color: var(--gray-font-color);
}
.comments-section-main .comments-section-items .comments-item-main .comments-item-detials .detials-message{
    font-size: 13px;
    font-weight: 400;
    color: var(--black-font-color);
    width: 100%;
    height: max-content;
    word-wrap: break-word

}
.comments-section-main .comments-section-items .comments-item-main .comments-item-detials .detials-tagged-users{
    width: 100%;
    height: max-content;
    display: flex;
    align-items: center;
    gap: 5px;
    flex-wrap: wrap;

}
.comments-section-main .comments-section-items .comments-item-main .comments-item-detials .detials-tagged-users .detials-tagged-user{
    width: max-content;
    padding: 2px 5px;

    font-size: 15px;
    font-weight: 500;

    background: var(--active-color);
    background: var(--transparent-active-color);
}


.comments-section-main  .comments-input-tag-main{

    position: absolute;
    bottom: 101%;
    left: 0;
    z-index: 10;

    width: max-content;
    min-width: 400px;
    height: max-content;
    max-height: 200px;
    background: var(--bg-light-color);
    box-shadow: 0 0 10px 0 #0000001a;
    border: 1px solid var(--border-light-color);
    padding: .5rem 0;

   display: flex;
   flex-direction: column;
   overflow-y: auto;
   overflow-x: hidden;

}
.comments-section-main  .comments-input-tag-users{
    
    width: 100%;
    height: max-content;
    display: flex;
    gap: 5px;
    flex-direction: column;

}
.comments-section-main .comments-input-tag-users .comments-input-tag-users-item{
    width: 100%;
    height: 40px;
    display: flex;
    align-items: center;
    gap: 10px;
    cursor: pointer;
    padding: 0 1rem;
    transition: all .25s;
    user-select: none;
}
.comments-section-main .comments-input-tag-users .comments-input-tag-users-item:hover{
    background: var(--hover-color);
}
.comments-section-main .comments-input-tag-users .comments-input-tag-users-item .user-profile{
    width: 30px;
    height: 30px;
    border-radius: 1000px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--light-font-color);
    font-size: 12px;
    font-weight: 550;
}
.comments-section-main .comments-input-tag-users .comments-input-tag-users-item .user-profile img{
    width: inherit;
    height: inherit;
    object-fit: cover;
    border-radius: inherit;
}
.comments-section-main .comments-input-tag-users .comments-input-tag-users-item .user-name{
    font-size: 15px;
    font-weight: 500;
}
.comments-section-main .comments-input-tagged-users{
    width: 100%;
    height: max-content;
    display: flex;
    align-items: center;
    gap: 10px;
}
.comments-section-main .comments-input-tagged-users .tagged-users-item{
    width: max-content;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
    cursor: pointer;
    padding: 0 10px;
    border-radius: 1000px;
    background: var(--transparent-active-color);
    color: var(--active-color);
    border: 1px solid var(--active-color);
}
.comments-section-main .comments-input-tagged-users .tagged-users-item .tagged-user-name{
    font-size: 12px;
    user-select: none;
    color: var(--active-color);
}
.comments-section-main .comments-input-tagged-users .tagged-users-item .tagged-user-close{
    width: 16px;
    height: 16px;
}
.comments-section-main .comments-input-tagged-users .tagged-users-item .tagged-user-close svg{
    width: inherit;
    height: inherit;
}
.comments-section-main .comments-input-tagged-users .tagged-users-item .tagged-user-close svg path{
    fill: var(--active-color);
}

@media only screen and (max-width: 600px) {

    .tabel-header tr th,
    .tabel-body tr td
    {
        padding: 5px 10px !important;
        font-size: 13px !important;
    }

    .attachments-section-main .attachment-input{
        width: 100px;
        height: 100px;
        border-radius: 4px;
    }
    .attachments-section-main .attachment-input .attachment-add-icon{
        width: 24px;
        height: 24px;
    }
    .attachments-section-main .attachment-input .attachment-label{
        font-size: 13px;
    }
    .attachments-section-main .attachment-input .attachment-limit{
        font-size: 12px;
    }
    .attachments-section-main .attachments-section-files .attachment-file{
        width: 100px;
        height: 100px;
        border-radius: 4px;
    }
    .attachments-section-main .attachments-section-files .attachment-file .attachment-file-footer .file-name{
        font-size: 10px;
    }
    .attachments-section-main .attachments-section-files .attachment-file .attachment-file-footer .file-download{
        width: 14px;
        height: 14px;
    }

    .comments-section-main .comments-section-items .comments-input-main .comments-input-usericon{
        width: 30px;
        height: 30px;
        font-size: 13px;
    }
    .comments-section-main .comments-section-items .comments-item-main .comments-item-usericon{
        width: 30px;
        height: 30px;
        font-size: 13px;
    }
    .comments-section-main .comments-section-items .comments-input-main .comments-input{
        width: calc(100% - 35px);
        gap: 0;
        padding: 5px;
    }
    .comments-section-main .comments-section-items .comments-item-main .comments-item-detials{
        width: calc(100% - 35px);
        gap: 0;
    }
    .comments-section-main .comments-section-items .comments-item-main .comments-item-detials .detials-head .left .name{
        font-size: 14px;
    }
    .comments-section-main .comments-section-items .comments-item-main .comments-item-detials .detials-head .left .time{
        font-size: 12px;
    }
}


/* Animation */

@keyframes sliderXLeft {
    20% {
        transform: translateX(10%);
        opacity: 1;
    }

    to {
        transform: translateX(0);
        opacity: 1;
    }
}

@keyframes sliderXRight {

    to {
        transform: translateX(0);
        opacity: 1;
    }
}


@keyframes sliderY {

    to {
        transform: translateY(0) scale(1);
        opacity: 1;

    }
}

@keyframes sliderYInvert {
    20% {
        transform: translateY(20%);
        opacity: 1;
    }

    to {
        transform: translateX(0);
        opacity: 1;
    }
}