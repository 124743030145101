
.project-footer-main{
    width: 100%;
    height: max-content;
    position: relative;
    overflow: hidden;
    font-family: 'Inter', sans-serif;
    display: flex;
    justify-content: center;

}
.project-footer-main .footer-copyrights{
   
    width: 100%;
    color: var(--gray-font-color);
    text-align: center;
    font-size: 17px;
    line-height: 100%;

    display: flex;
    align-items: center;
    justify-content: center;
}
.project-footer-main .footer-content-main{
    width: 100%;
    max-width: var(--max-width);
    height: max-content;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px 5%;
    gap: 20px;
    
}
.project-footer-main .footer-content{
    width: 100%;
    height: max-content;
    
    display: flex;
    justify-content: space-between;
}   
.project-footer-main .footer-content .footer-left{
    width: 300px;
   display: flex;
   flex-direction: column;
   
}   
.project-footer-main .footer-content .footer-left .logo{
   width: 200px;
   height: auto;
}   
.project-footer-main .footer-content .footer-left .logo img{
   width: inherit;
   height: auto;
}   
.project-footer-main .footer-content .footer-left .description{

   color: var(--mid-black-font-color);
   text-align: left;
   margin-top: 24px;
   margin-bottom: 32px;
   font-size: 17px;
   line-height: 150%;

}   
.project-footer-main .footer-content .footer-left .social-links{

 width: max-content;
 display: flex;
 align-items: center;
    gap: 10px;
}   
.project-footer-main .footer-content .footer-left .social-links .social-link{

    width: 50px;
    height: 50px;
    background: var(--bg-color);
    border-radius: 1000px;

    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: all .25s;
}     
.project-footer-main .footer-content .footer-left .social-links .social-link svg{

    margin-top: 5px;
}   

.project-footer-main .footer-content .footer-left .social-links .social-link:hover{

    background: var(--primary-color);
} 
.project-footer-main .footer-content .footer-left .social-links .social-link:hover svg path{

    fill: var(--light-font-color);
} 
.project-footer-main .footer-content .footer-right{

    width: 50%;
    height: max-content;
} 
.project-footer-main .footer-content .footer-right .right-items{

    width: 100%;
    height: max-content;
    display: flex;
    justify-content: flex-end;
    gap: 50px;
} 
.project-footer-main .footer-content .footer-right .right-items .right-subitem{

    display: flex;
    flex-direction: column;
    gap: 30px;
} 
.project-footer-main .footer-content .footer-right .right-items .right-subitem .title{
    white-space: nowrap;
    color: var(--primary-color);
    text-transform: uppercase;
    margin-bottom: 4px;
    font-size: 18px;
    font-weight: 600;
    line-height: 100%;
    text-decoration: none;
    cursor: pointer;
} 
.project-footer-main .footer-content .footer-right .right-items .right-subitem .items{
    white-space: nowrap;
    display: flex;
    flex-direction: column;
    gap: 15px;
    color: var(--mid-black-font-color);
    font-size: 16px;
    line-height: 100%;
    text-decoration: none;
    transition: color .3s;
} 

@media only screen and (max-width: 1000px) {
    .project-footer-main .footer-content{
        flex-direction: column;
        gap: 50px;
    }
    .project-footer-main .footer-content .footer-right{
        width: 100%;
        justify-content: center;
    }
    .project-footer-main .footer-content .footer-right .right-items{
        width: 100%;
        justify-content: center;
    }
   
}
@media only screen and (max-width: 600px) {
    .project-footer-main .footer-content .footer-right .right-items{
        width: 100%;
        justify-content: flex-start;
        flex-wrap: wrap;
        gap: 30px;
    }
    .project-footer-main .footer-content .footer-right .right-items .right-subitem .items{
        justify-content: flex-start;
    }

    .project-footer-main .footer-copyrights {
        font-size: 14px;

    }   
}