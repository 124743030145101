

.project-solutionfor-main{
    width: 100%;
    height: max-content;

    display: flex;
   justify-content: center;
}

.project-solutionfor-main .solutionfor-content-main{
    width: 100%;
    max-width: var(--max-width);
    height: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 60px;
    padding: 40px 5%;
    
}
.project-solutionfor-main .solutionfor-content-main .solutionfor-content-header{
    width: 100%;
    height: max-content;
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: center;
    
}


.project-solutionfor-main .solutionfor-content-main .solutionfor-content-header .title{
    font-size: 2.25rem;
    letter-spacing: .03em;
    font-weight: 900;
    transition: all .4s ease;
    color: var(--mid-black-font-color);
}
.project-solutionfor-main .solutionfor-content-main .solutionfor-content-header .description{
    font-size: 16px;
    font-weight: 400;
    text-align: center;
}
.project-solutionfor-main .solutionfor-content-main .solutionfor-cards{
    width: 100%;
    height: max-content;

    display: flex;
    justify-content: center;
    gap: 30px;
}
.project-solutionfor-main .solutionfor-content-main .solutionfor-card{
    width: 400px;
    height: 550px;
    border-radius: 10px;

    display: flex;
    flex-direction: column;
    background: var(--bg-light-color);
    box-shadow: 0px 1px 24px 0px rgba(0,0,0,0.14);
    gap: 10px;
}
.project-solutionfor-main .solutionfor-content-main .solutionfor-card .solutionfor-card-banner{
    width: 100%;
    height: 73%;
    border-radius: 10px 10px 0 0;

}
.project-solutionfor-main .solutionfor-content-main .solutionfor-card .solutionfor-card-banner img{
    width: 100%;
    height: 100%;
    border-radius: inherit;
    object-fit: cover;
}
.project-solutionfor-main .solutionfor-content-main .solutionfor-card .solutionfor-card-detials{
    width: 100%;
    height: max-content;

    padding: 0 20px 18px;

    display: flex;
    flex-direction: column;
    gap: 10px;
}
.project-solutionfor-main .solutionfor-content-main .solutionfor-card .solutionfor-card-detials .title{
    font-weight: 600;
    font-size: 24px;
    color: var(--primary-color);
    line-height: 1.2em;
}
.project-solutionfor-main .solutionfor-content-main .solutionfor-card .solutionfor-card-detials .description{
    font-weight: 400;
    font-size: 18px;
    color: var(--dark-font-color);
    line-height: 3vh;
}







@media only screen and (max-width: 1000px) {
    .project-solutionfor-main .solutionfor-content-main .solutionfor-card{
        width: 250px;
        min-height: 350px;
        height: max-content;
    }
    .project-solutionfor-main .solutionfor-content-main .solutionfor-card .solutionfor-card-detials{
        padding: 0 15px 18px;
    }
    .project-solutionfor-main .solutionfor-content-main .solutionfor-card .solutionfor-card-detials .title{
        font-size: 20px;
    }
    .project-solutionfor-main .solutionfor-content-main .solutionfor-card .solutionfor-card-detials .description{
        font-size: 14px;
    }

}

@media only screen and (max-width: 750px) {

    .project-solutionfor-main .solutionfor-content-main .solutionfor-cards{
        flex-direction: column;
    }
    .project-solutionfor-main .solutionfor-content-main .solutionfor-card{
        width: 100%;
        height: 180px;
        min-height: 180px;

        flex-direction: row;
        align-items: center;

    }
    .project-solutionfor-main .solutionfor-content-main .solutionfor-card .solutionfor-card-banner{
        width: 150px;
        height: 100%;
        border-radius: 10px 0 0 10px;
    }
    .project-solutionfor-main .solutionfor-content-main .solutionfor-card .solutionfor-card-banner img{
        border-radius: 10px 0 0 10px;
        height: 100%;
    }
    .project-solutionfor-main .solutionfor-content-main .solutionfor-card .solutionfor-card-detials{
        width: calc(100% - 150px);
        height: 100%;
        justify-content: center;
        padding: 0 10px 10px;
        gap: 5px;
    }
    

    .project-solutionfor-main .solutionfor-content-main .solutionfor-card .solutionfor-card-detials .title{
        font-size: 20px;
    }
    .project-solutionfor-main .solutionfor-content-main .solutionfor-card .solutionfor-card-detials .description{
        font-size: 18px;
        line-height:  2.5vh;
    }
}


@media only screen and (max-width: 700px) {
    .project-solutionfor-main .solutionfor-content-main .solutionfor-content-header .title{
        font-size: 26px;
        text-align: center;
    }
}

@media only screen and (max-width: 500px) {
    .project-solutionfor-main .solutionfor-content-main .solutionfor-card .solutionfor-card-detials .title{
        font-size: 20px;
    }
    .project-solutionfor-main .solutionfor-content-main .solutionfor-card .solutionfor-card-detials .description{
        font-size: 14px;
    }
}