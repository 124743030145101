.project-appbanner-main{
    width: 100%;
    height: max-content;
  
    position: relative;
    overflow: hidden;
    font-family: 'Inter', sans-serif;
    display: flex;
    justify-content: center;

}

.project-appbanner-main .appbanner-content-main{
    width: 100%;
    /* max-width: var(--max-width); */
    height:max-content;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    
}

.project-appbanner-main .appbanner-content-main .appbanner-content{
    width: 100%;
    height:100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.project-appbanner-main .appbanner-content-main .appbanner-content .appbanner-content-img{
    width: 100%;
    height: auto;
}
.project-appbanner-main .appbanner-content-main .appbanner-content .appbanner-content-img img{
    width: 100%;
    height: auto;
}
