.project-sectorscovered-main{
    width: 100%;
    height: max-content;
  
    position: relative;
    overflow: hidden;
    font-family: 'Inter', sans-serif;
    display: flex;
    justify-content: center;
}

.project-sectorscovered-main .sectorscovered-content-main{
    width: 100%;
    height: 100%;
    max-width: var(--max-width);
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 40px 5%;
    
}
.project-sectorscovered-main .sectorscovered-content-main .heading{
    font-size: 2.25rem;
    letter-spacing: .03em;
    font-weight: 900;
    transition: all .4s ease;
    color: var(--mid-black-font-color);
    margin-bottom: 1.5rem;
}
.project-sectorscovered-main .sectorscovered-items{
    margin-top: 2rem;
    width: 100%;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}
.project-sectorscovered-main .sectorscovered-item{

    width: 33%;
    height: max-content;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    gap: 15px;
    padding: 1.5rem 1rem;
}
.project-sectorscovered-main .sectorscovered-item .icon{
    
   width: 50px;
   height: 50px;
}
.project-sectorscovered-main .sectorscovered-item .icon svg{
    
   width: inherit;
   height: inherit;
}
.project-sectorscovered-main .sectorscovered-item .detials{
    
   width: 90%;
   display: flex;
   flex-direction: column;
   gap: 5px;
}
.project-sectorscovered-main .sectorscovered-item .detials .title{
    
    font-family: 'Inter', sans-serif;
    color: var(--mid-black-font-color);
    line-height: 1.3em;
    letter-spacing: .03em;
    font-size: 18px;
    font-weight: 700;
    -webkit-transition: all .4s ease;
    -moz-transition: all .4s ease;
    -o-transition: all .4s ease;
    transition: all .4s ease;
}
.project-sectorscovered-main .sectorscovered-item .detials .desc{
    font-size: 15px;
    line-height: 25px;
}

@media only screen and (max-width: 900px) {
    .project-sectorscovered-main .sectorscovered-item{
        width: 50%;
    }
}
@media only screen and (max-width: 700px) {
    .project-sectorscovered-main .sectorscovered-item{
        width: 100%;
    }
    .project-sectorscovered-main .sectorscovered-content-main .heading{
        font-size: 28px;
        white-space: nowrap;
        text-align: center;
    }
}