.project-trustBanner-main{
    width: 100%;
    height: max-content;
  
    position: relative;
    overflow: hidden;
    font-family: 'Inter', sans-serif;
    display: flex;
    justify-content: center;

    padding: 40px 5%;
}

.project-trustBanner-main .trustBanner-content-main{
    width: 100%;
    max-width: var(--max-width);
    height: max-content;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    
}
.project-trustBanner-main .trustBanner-content-main .trustBanner-content{
    width: 100%;
    height: max-content;
    padding: 55px 60px 55px 60px;
    background-image: linear-gradient(131deg, rgba(104, 113, 226, 1) 0%, rgba(255, 213, 246, 1) 100%);

    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 15px;
}
.project-trustBanner-main .trustBanner-content-main .trustBanner-content .trustBanner-left{
    word-wrap: break-word;
    word-break: break-word;

    margin-top: 0px !important;
    margin-bottom: 0px !important;
    text-align: left;
    color: var(--light-font-color);

    display: flex;
    flex-direction: column;

}

.project-trustBanner-main .trustBanner-content-main .trustBanner-content .trustBanner-left .title{
      font-size: 33px;
    line-height: 1.3;
    font-weight: 800;
}
.project-trustBanner-main .trustBanner-content-main .trustBanner-content .trustBanner-left .desc{
    font-size: 25px;
 
}
.project-trustBanner-main .trustBanner-right{
    display: flex;
    align-items: center;
}
.project-trustBanner-main .trustBanner-right .trustBanner-button{
    display: flex;
    align-items: center;
    border-radius: 5px;
    padding: 14px 32px 14px 32px;
    height: 50px;
    background: rgba(16, 33, 54,1);
    font-size: 16px;
    font-weight: 600;
    color: var(--light-font-color);
    white-space: nowrap;
    cursor: pointer;
}
@media only screen and (max-width: 1200px) {
    .project-trustBanner-main .trustBanner-content-main .trustBanner-content{
        width: 100%;
    }

}
@media only screen and (max-width: 1000px) {
    .project-trustBanner-main .trustBanner-content-main .trustBanner-content .trustBanner-left{
        font-size: 26px;
    }

}
@media only screen and (max-width: 800px) {
    .project-trustBanner-main .trustBanner-content-main .trustBanner-content{
        flex-direction: column;
        gap: 30px;
        padding: 30px 60px 30px 60px;
    }

}
@media only screen and (max-width: 600px) {
    .project-trustBanner-main .trustBanner-content-main .trustBanner-content{
        flex-direction: column;
        gap: 10px;
        padding: 15px 10px;
    }
    .project-trustBanner-main .trustBanner-content-main .trustBanner-content .trustBanner-left{
        text-align: center;
    }

    .project-trustBanner-main .trustBanner-content-main .trustBanner-content .trustBanner-left .title{
        font-size: 1.5rem;
    }
    .project-trustBanner-main .trustBanner-content-main .trustBanner-content .trustBanner-left .desc{
        font-size: 17px;
    }
    .project-customer_swipper-main .customer_swipper-header .header-title{
        font-size: 1.5rem !important;
    }
    .project-customer_swipper-main{
        padding: 20px 0 !important;
    }
    .project-customer_swipper-main .customer_swipper-content-main{
        padding: 0 !important;

    }

}