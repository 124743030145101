
.project-pricing-main{
    width: 100%;
    height: 100%;
    padding: 2rem;
    position: relative;
    font-family: 'Inter', sans-serif;
    display: flex;
    justify-content: center;

}
.project-content-container  .project-pricing-main{
    overflow-y: auto;
    overflow-x: hidden;
}

.project-pricing-main .pricing-content-main{
    width: 100%;
    max-width: var(--max-width);
    height: max-content;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;    
}
.project-pricing-main .pricing-content-main .pricing-content{
    width: 100%;
    height: max-content;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
}
.project-pricing-main .pricing-content .heading {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
}
.project-pricing-main .pricing-content .heading .title{
    font-size: 2rem;
    letter-spacing: .03em;
    font-weight: 900;
    transition: all .4s ease;
    color: var(--mid-black-font-color);
    text-align: center;
}
.project-pricing-main .pricing-content .heading .desc{
    max-width: 563px;
    font-size: 16px;
    font-weight: 400;
    line-height: 30px;
    text-align: center;
}
.project-pricing-main .pricing-type-toggles{
   
    width: 100%;
    height: max-content;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
}

.project-pricing-main .pricing-type-toggles .type-toggles-content{
   
    width: max-content;
    height: max-content;

    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    border-radius: 1000px;
    background: var(--bg-color);
    padding: 5px;
}
.project-pricing-main .pricing-type-toggles .type-toggles-content .type-toggles-item{
   
    width: max-content;
    height: max-content;
    color: var(--gray2-font-color);
    padding: 0.5em 1rem;
    cursor: pointer;
    border-radius: inherit;
    transition: all .25s;
    font-size: 16px;
    font-weight: 450;
    display: flex;
    justify-content: center;
    align-items: center;
}
.project-pricing-main .pricing-type-toggles .type-toggles-content .type-toggles-active{
   
    background: var(--dark-font-color);
    color: var(--light-font-color);
}
.project-pricing-main .pricing-type-toggles .toggle-notes{
    font-weight: 550;
    color: var(--active2-color);
    font-size: 16px;
}
.project-pricing-main .pricing-plans{
    width: 100%;
    height: max-content;
    display: flex;
    justify-content: center;
}
.project-pricing-main .pricing-plans .plans-content{
    width: 100%;
    height: max-content;
    display: flex;
    justify-content: center;
    gap: 20px;
    flex-wrap: wrap;
}
.project-pricing-main .plans-content .plan-item{
   
    width: 360px;
    height: 1000px;
    padding: 2em 1em; 
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border: 1px solid var(--border-dark-color);
    border-radius: 4px;
    position: relative;
}
.project-pricing-main .plans-content .plan-popular{
   
    border: 3px solid var(--primary-color);
}
.project-pricing-main .plans-content .plan-popular .plan-popular-label{
   
    position: absolute;
    top: 5px;
    right: 5px;
    width: max-content;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 1000px;

    background: var(--primary-color);
    color: var(--light-font-color);
    font-size: 16px;
    font-weight: 600;
    padding: 0 1rem;
    white-space: nowrap;
}
.project-pricing-main .plans-content .plan-item .plan-item-content{
   
    width: 100%;
    height: max-content;
    display: flex;
    flex-direction: column;
}
.project-pricing-main .plans-content .plan-item .plan-mobileview-showdetials-btn{
   
    position: absolute;
    bottom: -22px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 8;

    width: 40px;
    height: 40px;
    border-radius: 1000px;
    background: var(--dark-font-color);
    display: none;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}
.project-pricing-main .plans-content .plan-item .plan-mobileview-showdetials-btn .icon {
    width: 20px;
    height: 20px;
}
.project-pricing-main .plans-content .plan-item .plan-mobileview-showdetials-btn .icon svg{
    width: inherit;
    height: inherit
}
.project-pricing-main .plans-content .plan-item .plan-mobileview-showdetials-btn .icon svg path{
    stroke: var(--light-font-color);
}
.project-pricing-main .plans-content .plan-item .plan-header{
   
    width: 100%;

    display: flex;
    flex-direction: column;
    gap: 10px;
    padding-bottom: 1rem;

    border-bottom: 1px solid var(--border-dark-color);
}
.project-pricing-main .plan-item .plan-header .plan-title{

    font-size: 28px;
    font-weight: 600;
    display: flex;
    justify-content: center;
   
}
.project-pricing-main .plan-item .plan-header .plan-price{

   display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
}
.project-pricing-main .plan-item .plan-header .plan-price .price{
  
    display: flex;
    align-items: flex-start;
    gap: 5px;
    color: var(--mid-black-font-color);
}
.project-pricing-main .plan-item .plan-header .plan-price .price .price-currency{
    font-size: 25px;
    font-weight: 600;
    margin-top: 12px;
}
.project-pricing-main .plan-item .plan-header .plan-price .price .price-value{
    font-size: 60px;
    font-weight: 900;
}
.project-pricing-main .plan-item .plan-header .plan-price .gst{

    font-size: 15px;
    font-weight: 500;
}
.project-pricing-main .plan-item .plan-header .plan-explain{

   font-size: 15px;
   color: var(--gray2-font-color);
   text-align: center;
}
.project-pricing-main .plan-item .plan-detials{

   width: 100%;
   height: max-content;
   display: flex;
   flex-direction: column;
   gap: 20px;
   padding: 20px 0;
}
.project-pricing-main .plan-item .plan-detials .plan-detials-title{

   font-size: 18px;
   font-weight: 500;
}
.project-pricing-main .plan-item .plan-detials .plan-detials-items{
margin-top: 1rem;
    width: 100%;
display: flex;
gap: 20px;
flex-direction: column;
}
.project-pricing-main .plan-item .plan-detials .plan-detials-items .plain-detials-item{
    width: 100%;
    display: flex;
    align-items: center;
    gap: 10px;
}
.project-pricing-main .plan-item .plan-detials .plan-detials-items .plain-detials-item .icon {
    width: 14px;
    height: 14px;
}
.project-pricing-main .plan-item .plan-detials .plan-detials-items .plain-detials-item .icon svg{
    width: inherit;
    height: inherit;
}
.project-pricing-main .plan-item .plan-detials .plan-detials-items .plain-detials-item .icon svg path{
   stroke: #000;
}
.project-pricing-main .plan-item .plan-detials .plan-detials-items .plain-detials-item .label{
   font-size: 15px;
   font-weight: 400;
}
.project-pricing-main .plan-item .plan-button{
   
    margin-top: 1rem;
    width: 100%;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #f5ba31;
    /* background-image: linear-gradient(135deg,rgb(255, 106, 0, 0), var(--primary-color)); */
    color: var(--dark-font-color);
    cursor: pointer;
    font-weight: 600;
    font-size: 16px;
    border-radius: 10px;
    gap: 5px;
    transition: all .25s;
}



@media only screen and (max-width: 1000px) {

    .project-pricing-main{
        padding: 1rem;
    }

    .project-pricing-main .pricing-plans .plans-content{
        gap: 10px;
    }
    .project-pricing-main .plans-content .plan-item{
        width: 300px;
        padding: 1rem;
    }

}

@media only screen and (max-width: 650px) {
    .project-pricing-main .pricing-content .heading .title{
        font-size: 24px;
    }
    .project-pricing-main .pricing-content .heading .desc{
        font-size: 14px;
        line-height: 20px;
    }
    .project-pricing-main .plans-content .plan-item{
        width: 100%;
        height: max-content;
        padding-bottom: 2rem;
    }
    .project-pricing-main .pricing-plans .plans-content{
        gap: 40px;
    }
    .project-pricing-main .plan-item .plan-mobileview-disabled{
        display: none;
    }
    .project-pricing-main .plans-content .plan-item .plan-mobileview-showdetials-btn{
        display: flex !important;
    }
    .project-pricing-main .pricing-type-toggles{
        flex-direction: column;
    }
}