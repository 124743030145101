



.project-benefits-main{
    width: 100%;
    height: max-content;

    display: flex;
   justify-content: center;
}

.project-benefits-main .benefits-content-main{
    width: 100%;
    max-width: var(--max-width);
    height: 100%;
    position: relative;
    display: flex;
    align-items: center;
    padding: 40px 5%;
    
}
.project-benefits-main .benefits-content-main .benefits-content-right{
    width: 50%;
    height: 100%;
}
.project-benefits-main .benefits-content-main .benefits-content-right img{
    width:100%;
    height: 100%;
    object-fit: contain;
}

.project-benefits-main .benefits-content-main .benefits-content-left{
    width: 50%;
    height: 100%;

    display: flex;
    flex-direction: column;
    gap: 30px;
    justify-content: center;
}

.project-benefits-main .benefits-content-main .benefits-content-left .title{
    font-weight: 600;
    font-size: 50px;
    color: var(--dark-font-color);
    line-height: 60px;
}
.project-benefits-main .benefits-content-main .benefits-content-left .benefits-checklist-items{
    display: flex;
    flex-direction: column;
    gap: 15px;
}
.project-benefits-main .benefits-content-main .benefits-content-left .benefits-checklist-item{
    display: flex;
    align-items: center;
    gap: 15px;
}
.project-benefits-main .benefits-content-main .benefits-content-left .benefits-checklist-item .icon{
    width: 24px;
    height: 24px;
}
.project-benefits-main .benefits-content-main .benefits-content-left .benefits-checklist-item .value{
    font-size: 16px;
    color: var(--dark-font-color);
    font-weight: 450;
}
.project-benefits-main .benefits-content-main .benefits-content-left .benefits-checklist-item .icon svg{
    width: inherit;
    height: inherit;
    fill: var(--success-color);
}
.project-benefits-main .benefits-content-main .benefits-content-left .benefits-action-buttons{
    display: flex;
    align-items: center;
}
.project-benefits-main .benefits-content-main .benefits-content-left .benefits-action-buttons .benefits-button{
    width: max-content;
    height: 50px;

    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 20px;
    
    background: var(--primary-color);
    color: var(--light-font-color);
    border-radius: 10px;
}

@media only screen and (max-width: 1000px) {
    .project-benefits-main .benefits-content-main .benefits-content-left .title{
        font-size: 38px;
        line-height: 50px;
    }
}
@media only screen and (max-width: 800px) {
    .project-benefits-main .benefits-content-main{
        flex-direction: column;
    }
    .project-benefits-main .benefits-content-main .benefits-content-left{
        width: 100%;
    }
    .project-benefits-main .benefits-content-main .benefits-content-right{
        width: 100%;
    }
}